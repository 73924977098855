import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import { Button } from '../components/Button'

import { PageLinkUrl } from '../constants'

const NotFoundPage = () => (
  <Layout>
    <section>
      <div className="container-xl section-py-normal">
        <div className="max-w-2xl mx-auto text-center my-12">
          <h2 className="text-4xl md:text-6xl font-medium">
            404: Page not found.
          </h2>
          <p className="text-xl my-16">
            Beaver ไม่เจอหน้าที่คุณต้องการหา กรุณากลับไปที่หน้าหลักก่อนนะคร้าบ
          </p>
          <Link to={PageLinkUrl.INDEX}>
            <Button text="กลับหน้าหลัก" />
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
